import { getPercentChange } from '../../utils/data'
import { ProtocolData } from '../../state/protocol/reducer'
import gql from 'graphql-tag'
import { useQuery, ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useDeltaTimestamps } from 'utils/queries'
import { useBlocksFromTimestamps } from 'hooks/useBlocksFromTimestamps'
import { useMemo } from 'react'
import { useClients } from 'state/application/hooks'
import { useTVLOffset } from './derived'

export const GLOBAL_DATA = (block?: string) => {
  const queryString = ` query uniswapFactories {
      factories(
       ${block !== undefined ? `block: { number: ${block}}` : ``} 
       first: 1, subgraphError: allow) {
        txCount
        totalVolumeUSD
        totalFeesUSD
        totalValueLockedUSD
      }
    }`
  return gql(queryString)
}
export const GLOBAL_DATA_V1 = (block?: string) => {
  const queryString = ` query uniswapFactories {
    uniswapFactories(
       ${block !== undefined ? `block: { number: ${block}}` : ``} 
       first: 1, subgraphError: allow) {
        txCount
        totalVolumeUSD
        totalLiquidityUSD
      }
    }`
  return gql(queryString)
}

interface GlobalResponse {
  factories: {
    txCount: string
    totalVolumeUSD: string
    totalFeesUSD: string
    totalValueLockedUSD: string
  }[]
}
interface GlobalResponseV1 {
  uniswapFactories: {
    txCount: string
    totalVolumeUSD: string
    totalLiquidityUSD: string
  }[]
}

export function useFetchProtocolData(
  dataClientOverride?: ApolloClient<NormalizedCacheObject>,
  blockClientOverride?: ApolloClient<NormalizedCacheObject>,
  v1ClientOverride?: ApolloClient<NormalizedCacheObject>,
): {
  loading: boolean
  error: boolean
  data: ProtocolData | undefined
} {
  // get appropriate clients if override needed
  const { dataClient, blockClient, v1Client } = useClients()
  const activeDataClient = dataClientOverride ?? dataClient
  const activeBlockClient = blockClientOverride ?? blockClient
  const activeV1Client = v1ClientOverride ?? v1Client

  // Aggregate TVL in inaccurate pools. Offset Uniswap aggregate TVL by this amount.
  const tvlOffset = useTVLOffset()

  // get blocks from historic timestamps
  const [t24, t48] = useDeltaTimestamps()
  const { blocks, error: blockError } = useBlocksFromTimestamps([t24, t48], activeBlockClient)
  const [block24, block48] = blocks ?? []

  // fetch all data
  const { loading, error, data } = useQuery<GlobalResponse>(GLOBAL_DATA(), { client: activeDataClient })
  const {
    loading: loadingv1,
    error: errorv1,
    data: datav1,
  } = useQuery<GlobalResponseV1>(GLOBAL_DATA_V1(), { client: activeV1Client })

  const {
    loading: loading24,
    error: error24,
    data: data24,
  } = useQuery<GlobalResponse>(GLOBAL_DATA(block24?.number ?? 0), { client: activeDataClient })
  const {
    loading: loading24v1,
    error: error24v1,
    data: data24v1,
  } = useQuery<GlobalResponseV1>(GLOBAL_DATA_V1(block24?.number ?? 0), { client: activeV1Client })

  const {
    loading: loading48,
    error: error48,
    data: data48,
  } = useQuery<GlobalResponse>(GLOBAL_DATA(block48?.number ?? 0), { client: activeDataClient })
  const {
    loading: loading48v1,
    error: error48v1,
    data: data48v1,
  } = useQuery<GlobalResponseV1>(GLOBAL_DATA_V1(block48?.number ?? 0), { client: activeV1Client })

  const anyError = Boolean(error || errorv1 || error24 || error24v1 || error48 || error48v1 || blockError)
  const anyLoading = Boolean(loading || loadingv1 || loading24 || loading24v1 || loading48 || loading48v1)

  const parsed = data?.factories?.[0]
  const parsed24 = data24?.factories?.[0]
  const parsed48 = data48?.factories?.[0]
  const parsedv1 = datav1?.uniswapFactories?.[0]
  const parsed24v1 = data24v1?.uniswapFactories?.[0]
  const parsed48v1 = data48v1?.uniswapFactories?.[0]

  const formattedData: ProtocolData | undefined = useMemo(() => {
    if (anyError || anyLoading || !parsed || !parsedv1 || !blocks || tvlOffset === undefined) {
      return undefined
    }

    // volume data
    const volumeUSD =
      parsed && parsed24
        ? parseFloat(parsed.totalVolumeUSD) - parseFloat(parsed24.totalVolumeUSD)
        : parseFloat(parsed.totalVolumeUSD)
    const volumeUSDV1 =
      parsedv1 && parsed24v1
        ? parseFloat(parsedv1.totalVolumeUSD) - parseFloat(parsed24v1.totalVolumeUSD)
        : parseFloat(parsedv1.totalVolumeUSD)
    const volumeUSDSUM = volumeUSD + volumeUSDV1

    const volumeOneWindowAgo =
      parsed24?.totalVolumeUSD && parsed48?.totalVolumeUSD
        ? parseFloat(parsed24.totalVolumeUSD) - parseFloat(parsed48.totalVolumeUSD)
        : undefined
    const volumeOneWindowAgoV1 =
      parsed24v1?.totalVolumeUSD && parsed48v1?.totalVolumeUSD
        ? parseFloat(parsed24v1.totalVolumeUSD) - parseFloat(parsed48v1.totalVolumeUSD)
        : undefined
    const volumeOneWindowAgoSUM =
      volumeOneWindowAgo && volumeOneWindowAgoV1 ? volumeOneWindowAgo + volumeOneWindowAgoV1 : undefined
    // console.log('#volumn24#', volumeUSD, volumeUSDV1, volumeOneWindowAgo, volumeOneWindowAgoV1)
    // console.log(parsed, parsed24, parsedv1, parsed24v1)

    // const volumeUSDChange =
    //   volumeUSD && volumeOneWindowAgo ? ((volumeUSD - volumeOneWindowAgo) / volumeOneWindowAgo) * 100 : 0
    const volumeUSDChange =
      volumeUSDSUM && volumeOneWindowAgoSUM ? ((volumeUSDSUM - volumeOneWindowAgoSUM) / volumeOneWindowAgoSUM) * 100 : 0

    // total value locked
    // const tvlUSDChange = getPercentChange(parsed?.totalValueLockedUSD, parsed24?.totalValueLockedUSD)
    const tvlNow = parseFloat(parsed?.totalValueLockedUSD) + parseFloat(parsedv1.totalLiquidityUSD)
    const tvl24 = parseFloat(parsed24?.totalValueLockedUSD || '') + parseFloat(parsed24v1?.totalLiquidityUSD || '')
    const tvlUSDChange = tvl24 ? ((tvlNow - tvl24) / tvl24) * 100 : 0

    // console.log('#tvl#', parsed, parsed24, parsedv1, parsed24v1)

    // 24H transactions
    const txCount =
      parsed && parsed24 ? parseFloat(parsed.txCount) - parseFloat(parsed24.txCount) : parseFloat(parsed.txCount)

    const txCountOneWindowAgo =
      parsed24 && parsed48 ? parseFloat(parsed24.txCount) - parseFloat(parsed48.txCount) : undefined

    const txCountChange =
      txCount && txCountOneWindowAgo ? getPercentChange(txCount.toString(), txCountOneWindowAgo.toString()) : 0

    const feesOneWindowAgo =
      parsed24 && parsed48 ? parseFloat(parsed24.totalFeesUSD) - parseFloat(parsed48.totalFeesUSD) : undefined
    const feesOneWindowAgoV1 = volumeOneWindowAgoV1 ? volumeOneWindowAgoV1 * 0.003 : 0
    const feesOneWindowAgoSUM = feesOneWindowAgo ? feesOneWindowAgo + feesOneWindowAgoV1 : 0

    const feesUSD =
      parsed && parsed24
        ? parseFloat(parsed.totalFeesUSD) - parseFloat(parsed24.totalFeesUSD)
        : parseFloat(parsed.totalFeesUSD)
    const feesUSDV1 = volumeUSDV1 * 0.003
    const feesUSDSUM = feesUSD + feesUSDV1

    // const feeChange =
    //   feesUSD && feesOneWindowAgo ? getPercentChange(feesUSD.toString(), feesOneWindowAgo.toString()) : 0
    const feeChange = feesOneWindowAgoSUM ? ((feesUSDSUM - feesOneWindowAgoSUM) / feesOneWindowAgoSUM) * 100 : 0

    return {
      volumeUSD: volumeUSDSUM,
      volumeUSDChange: typeof volumeUSDChange === 'number' ? volumeUSDChange : 0,
      tvlUSD: tvlNow - tvlOffset,
      tvlUSDChange,
      feesUSD: feesUSDSUM,
      feeChange,
      txCount,
      txCountChange,
    }
  }, [anyError, anyLoading, blocks, parsed, parsedv1, parsed24, parsed24v1, parsed48, parsed48v1, tvlOffset])
  return {
    loading: anyLoading,
    error: anyError,
    data: formattedData,
  }
}

import Tooltip from 'components/Tooltip'
import React, { useState } from 'react'
import styled from 'styled-components'
import TipsIcon from '../../assets/images/tipsIcon.png'
const TextWrapper = styled.div<{
  $margin: boolean
  $link: boolean
  color?: string
  fontSize?: string
  $adjustSize?: boolean
  $mt?: string
}>`
  width: fit-content;
  position: relative;
  margin-left: ${({ $margin }) => $margin && '4px'};
  color: ${({ theme, $link, color }) => ($link ? theme.blue1 : color ?? theme.text1)};
  font-size: ${({ fontSize }) => fontSize ?? 'inherit'};

  :hover {
    cursor: pointer;
  }
  img {
    width: 16px;
    height: 16px;
    margin-top: ${({ $mt }) => $mt};
  }

  @media screen and (max-width: 600px) {
    font-size: ${({ $adjustSize }) => $adjustSize && '12px'};
  }
`

const HoverInlineText = ({
  text,
  maxCharacters = 20,
  margin = false,
  adjustSize = false,
  fontSize,
  color,
  link,
  imgIcon = false,
  mt = '0',
  ...rest
}: {
  text: string
  maxCharacters?: number
  margin?: boolean
  adjustSize?: boolean
  fontSize?: string
  color?: string
  link?: boolean
  imgIcon?: boolean
  mt?: string
}) => {
  const [showHover, setShowHover] = useState(false)

  if (!text) {
    return <span></span>
  }

  if (text.length > maxCharacters) {
    return (
      <Tooltip text={text} show={showHover}>
        <TextWrapper
          onMouseEnter={() => setShowHover(true)}
          onMouseLeave={() => setShowHover(false)}
          $margin={margin}
          $adjustSize={adjustSize}
          $link={!!link}
          color={color}
          fontSize={fontSize}
          $mt={mt}
          {...rest}
        >
          {imgIcon ? <img src={TipsIcon} /> : `${text.slice(0, maxCharacters - 1) + '...'}`}
        </TextWrapper>
      </Tooltip>
    )
  }

  return (
    <TextWrapper color={color} $margin={margin} $adjustSize={adjustSize} $link={!!link} fontSize={fontSize} {...rest}>
      {text}
    </TextWrapper>
  )
}

export default HoverInlineText

import SCROLL_LOGO_URL from '../assets/images/scroll-logo.png'
import { ChainId } from '@zebra.xyz/sdk-core'

export enum SupportedNetwork {
  SCROLL,
  SCROLL_SEPOLIA,
}

export type NetworkInfo = {
  chainId: ChainId
  id: SupportedNetwork
  route: string
  name: string
  imageURL: string
  bgColor: string
  primaryColor: string
  secondaryColor: string
}

export const ScrollNetworkInfo: NetworkInfo = {
  chainId: ChainId.SCROLL,
  id: SupportedNetwork.SCROLL,
  route: 'scroll',
  name: 'Scroll',
  bgColor: '#EBC28E',
  primaryColor: '#18FF00',
  secondaryColor: '#18FF00',
  imageURL: SCROLL_LOGO_URL,
}

export const ScrollSepoliaNetworkInfo: NetworkInfo = {
  chainId: ChainId.SCROLL_SEPOLIA,
  id: SupportedNetwork.SCROLL_SEPOLIA,
  route: 'scroll_sepolia',
  name: 'Scroll Sepolia',
  bgColor: '#EBC28E',
  primaryColor: '#18FF00',
  secondaryColor: '#18FF00',
  imageURL: SCROLL_LOGO_URL,
}

export const SUPPORTED_NETWORK_VERSIONS: NetworkInfo[] = [ScrollNetworkInfo, ScrollSepoliaNetworkInfo]
